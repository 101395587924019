import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { YukkApi } from '../../../service/yukkapi.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { first } from 'rxjs/operators';
import { marked } from 'marked';
import { RoutingService } from '../../../service/routing.service';
import { ActivatedRoute } from '@angular/router';
import { ChatMessage, AIMessage, ChatAIMessage, SourceDoc } from '../interfaces/chart-details.interface'


import { switchMap, map } from 'rxjs/operators';
import { iif, of } from 'rxjs';

import * as moment from 'moment';
import { NewsViewComponent } from 'src/app/cockpit/main-news/news-newsfeed/news-view/news-view.component';
import { ModalReadonlyStateService } from 'src/app/cockpit/main-news/news-newsfeed/news-view/news-view-readonly-state-service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
   
})
export class ChatComponent implements OnInit {

    headerText = 'YUKKA Lab News LLM';
    userAvatar = 'YOU';
    llmAvatar = 'YUKKA';
    chatInput: string; // question from user
    chatOutput = []; // answer from LLM stored in an array 
    chatHistory = []; // storing chatInput (called message) and answer (called history) in an array 
   
    loading = false;
    error = false;
    params: any;
    chatDetails:(ChatMessage | ChatAIMessage)[];

    // properties for history sent in auth.llmChat
    chatIdQuestion = '';
    chatIdAnswer: string | null  = '';
    chatIdHistory: [string, string][] = [];;
    chatTitle = '';


    // passing chatId and chatTitle to chatNavigation Component (child component)
    parentMessageChatTitle: string;
    parentMessageChatId: string;

    // closing or opening chat charts component and storing its state in localStorage
    chartVisible: boolean = true;

    


    constructor(
      public auth: AuthService,
      public yukkApi: YukkApi,
      public dialog: MatDialog,
      public snackBar: MatSnackBar,
      public routing: RoutingService,
      private route: ActivatedRoute, 
      private readonlyStateService: ModalReadonlyStateService

    ) {

      this.route.queryParams.subscribe(params => {
        this.params = params;
      });

    }
   

    ngOnInit(): void {
      this.loadChartVisibility();
      this.route.queryParams.subscribe(params => {
        const chatId = params['chatId'];
        if (chatId) {
            this.loadConversation(chatId);
        }
    });
    }

    // prevents re-rendering sub-components
    trackByChatId(index, chat) {
      return chat.message_id;
    }
    /**
     * Checks if the given message is an instance of AIMessage.
     * 
     * @param message - The message to check.
     * @returns True if the message is an instance of AIMessage, false otherwise.
     */
    isAIMessage(message: string | AIMessage): message is AIMessage {
      return (message as AIMessage).entityName !== undefined;
    }

    // loading conversation based on ChatId 
    loadConversation(chatId: string) {
      this.chatIdHistory = [];
      // get chat conversation based on chatId
      this.auth.getChatDetails(chatId).subscribe(chatDetails => {
        this.chatDetails = (chatDetails as any).messages.map(chat => {
          // determining which message belongs to ai or human 
              if (chat.actor === 'ai') {
                  let parsedMessage: AIMessage | string = chat.message;
                  if (typeof chat.message === 'string') {
                    try {
                        parsedMessage = this.parseJsonString(chat.message) as AIMessage;
                        (chat as ChatAIMessage).message = parsedMessage;
                    } catch (e) {
                        console.error('Error parsing AI message:', e);
                    }
                }
                
                  if (typeof parsedMessage === 'object') {
                    const companyInfo = this.extractCompanyInfo(parsedMessage.requested_entities);
                    const articlesToUse = this.articlesWithEventsAndCitations(parsedMessage);
                    (chat as ChatAIMessage).message = this.processChatMessage(parsedMessage, articlesToUse, companyInfo);
                      if (parsedMessage.summary?.text?.length > 0) {
                          this.chatIdAnswer = parsedMessage.summary.text;
                      } else if (!parsedMessage.summary && parsedMessage.messages?.length > 0) {
                          this.chatIdAnswer = parsedMessage.messages[0];
                      } else if (!parsedMessage.summary && (!parsedMessage.messages || parsedMessage.messages.length === 0) && parsedMessage.facts) {
                          this.chatIdAnswer = parsedMessage.facts.map((fact: any) => fact.fact).join(' ');
                      } else {
                          console.log('No valid AI message found.');
                      }

                  }
              } else if (chat.actor === 'human') {
                  this.chatIdQuestion = chat.message;
              }
  
              // Push the pair [question, answer] to chatIdHistory array
              if (this.chatIdQuestion && this.chatIdAnswer) {
                  this.chatIdHistory.push([this.chatIdQuestion, this.chatIdAnswer]);
                  this.chatIdQuestion = '';
                  this.chatIdAnswer = '';
                  this.chatTitle = this.chatIdHistory[0][0];
                  this.parentMessageChatTitle = this.chatTitle;
                  this.parentMessageChatId = chatId;
              }

              return chat;
          });
          
      }, error => {
          console.error('Error fetching chat details:', error);
      });
  }
  
  
  
    chatFeedback(chatId: string, messageId: string, feedback: string) {
     
      const chat = this.chatDetails.find((chat: any) => chat.message_id === messageId);
    
      if (!chat) {
        console.log(`Chat with message_id ${messageId} not found`);
        return;
      }

      if (chat) {
        const feedbackValue = feedback === 'pos' ? 1 : -1;
    
        // check if feedback already exists
        if (((chat as any).feedback ?? null) === feedbackValue) {
          this.auth.deleteChatFeedback(chatId, messageId).subscribe({
            next: (res) => {
              // console.log('Feedback deleted', res);
              (chat as any).feedback = null;  // set back to null
            },
            error: (error) => {
              console.log('Failed to delete feedback', error);    
            }
          });
        } else {
          // Set feedback
          this.auth.postChatFeedback(chatId, messageId, feedback).subscribe({
            next: (res) => {
              // console.log('Feedback sent', res);
              (chat as any).feedback = feedbackValue;
            },
            error: (error) => {
              console.log('Failed to send feedback', error);
              alert('Failed to send feedback, please try again')
            }
          });
        }
      } 
    }


    submitRelatedQuestions(question) {
      if (question && (typeof question === 'string') && !this.loading){
        this.postTheMessage(question);  
      }
    }



    // post request into chatId conversation
    postChatConversation(chatId: string, actor: string, message: string): void {
      this.auth.postChatConversation(chatId, actor, message).subscribe({
        next: (response) => {
          this.loadConversation(chatId);
        },
        error: (err) => console.error('Error posting message:', err)
      });
    }

    postTheMessage(userQuestion:string): void {
      setTimeout(()=> {
        const target = document.getElementById('llmContainer');
        target?.scrollTo(0, target.scrollHeight);
      }, 500);
      this.loading = true;
      const message = userQuestion.trim(); //question
      const history = JSON.parse(JSON.stringify(this.chatIdHistory)); //answer
      const chatId = this.params.chatId; // chatId
      this.chatInput = ''; 
      if (!this.error) { 
        this.chatHistory.push([marked.parse(message), '']); 
        this.chatOutput.push({}); 
      } else {
        this.chatHistory[this.chatHistory.length-1][0] = message;
      }
      this.error = false;
      this.postChatConversation(chatId, 'human', message);


      // post message and history into v2/chat endpoint
      this.auth.chatLLM(message, history).pipe(
        first(),
        switchMap(res => {
          const copyRes = JSON.parse(JSON.stringify(res));
          const copyRes2 = JSON.parse(JSON.stringify(res));
          this.chatHistory[this.chatHistory.length - 1][1] = marked.parse(copyRes.history[copyRes.history.length - 1][1]);
      
          return iif(
            () => copyRes2.requested_entities && copyRes2.requested_entities.length > 0,
            this.yukkApi.getEntitiesInfo(copyRes2.requested_entities ? [copyRes2.requested_entities[0]] : [], 'array').pipe(
              map(entitiesRes => {
                const entityName = entitiesRes[0].name;
                return {copyRes2, entityName  };
              })
            ),
            of({ copyRes2, entityName: null })
          );
        })
      ).subscribe(
        ({ copyRes2, entityName }) => {
          this.processResponse(copyRes2, entityName);
        },
        error => {
          this.handleError(error);
        }
      );
      
    }  

    onSubmit(event) {
        if (this.chatInput && (typeof this.chatInput === 'string') && this.chatInput.trim() && !this.loading) { 
          this.postTheMessage(this.chatInput)    
      }}

      extractCompanyInfo(requestedEntities: string[]): { companyName: string, companyType: string } {
        let companyName = '';
        let companyType = '';
        
        if (requestedEntities && requestedEntities.length > 0) {
          const firstEntity = requestedEntities[0];
          const [type, name] = firstEntity.split('.');
          companyName = name;
          companyType = type;
        }
        
        return { companyName, companyType };
      }

      articlesWithEventsAndCitations(aiMessage: AIMessage): SourceDoc[] {
        const addedArticleIds = new Set<string>();
        const sourceDocumentsWithEvents = aiMessage.source_documents?.reduce((acc, article) => {
          if (!addedArticleIds.has(article.id)) {
              const matchingEvents = aiMessage.events?.filter(event =>
                  event.document_ids.includes(article.id)
              ).map(event => event.name);
  
              acc.push({
                  ...article,
                  eventNames: matchingEvents || [], 
              });
  
              addedArticleIds.add(article.id);
          }
          return acc;
      }, []);

        let factCitationCounter = 1;
        aiMessage?.facts?.forEach((fact: any) => {
          if(fact.source_doc_ids.length > 0) {
            fact.source_doc_ids.map((source) => {
              const sourceDoc = sourceDocumentsWithEvents.find((doc: any) => doc.id === source);
              if(sourceDoc) {
                if(!sourceDoc.citation) {
                sourceDoc.citation = factCitationCounter++;
                }
              }
          });
        }
        });

        if(sourceDocumentsWithEvents?.length > 0 ){
          sourceDocumentsWithEvents.sort((a, b) => {
            if (a.citation !== undefined && b.citation !== undefined) {
              return a.citation - b.citation;
            }
            if (a.citation !== undefined) {
              return -1;
            }
            if (b.citation !== undefined) {
              return 1;
            }
            return new Date(b.publish_time).getTime() - new Date(a.publish_time).getTime();
          });

        }
       
        return sourceDocumentsWithEvents;
        
      }

      processResponse(copyRes2:any, entityName: string | null) {
        if (!copyRes2.facts || copyRes2.facts.length === 0) {
          this.chatOutput[this.chatOutput.length - 1] = Object.assign({}, copyRes2, {
            entityName
          });
        } else {
          this.chatOutput[this.chatOutput.length - 1] = Object.assign({}, copyRes2, {
            entityName
          });
        }
        const chatId = this.params.chatId;
        this.loading = false;
      
        if (chatId) {
          const stringifiedAiMessage = JSON.stringify(this.chatOutput[this.chatOutput.length - 1]);
          this.postChatConversation(chatId, 'ai', stringifiedAiMessage);
        }
      }

      processChatMessage(message: AIMessage, articlesToUse?: SourceDoc[], companyInfo?: { companyName: string, companyType: string }) {
        const processedMessage = { ...message };
      
        if (articlesToUse) {
          processedMessage.source_documents_events = articlesToUse;
        }
      
        if (companyInfo) {
          processedMessage.companyName = companyInfo.companyName;
          processedMessage.companyType = companyInfo.companyType;
        }

        return processedMessage;
      }
    
   
      private handleError(error: any) {
        this.error = true;
        this.loading = false;
        this.snackBar.open('Something went wrong. Please try again later.', 'OK', { duration: 10000 });
      }
      

    showTheAricleView(companyName: string, companyType: string, article: SourceDoc ): void{
      const { id: articleId , publish_time, eventNames } = article;
      const publishedDate = publish_time.split('T')[0];
      const dateFrom = publishedDate;
      const dateTo = moment(dateFrom).add(1, 'days');
      const formattedDateStringTo = dateTo.format('YYYY-MM-DD');

      if (articleId) {
        this.openTheNewsDetailModal(companyName, companyType, articleId, dateFrom, formattedDateStringTo, eventNames);
      } else {
        console.error('No articleId provided');
      }
    }

    navigateToCompany(companyName: string, companyType: string): void {
      const url = `/cockpit/news/market/chart?id=${companyName}&type=${companyType}`;
      window.open(url, '_blank');
    }


    parseJsonString(jsonString: string): any {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error('Error parsing JSON string:', e);
        return jsonString; // Return the original string if it's not a valid JSON
      }
    }

    private openTheNewsDetailModal(companyName: string, companyType: string, articleId: string, dateFrom:string, dateTo:string, eventNames?: string[]): void {
      const params = {
        news: articleId,
        time: `${dateFrom}--${dateTo}`,
        type: companyType,
        id: companyName
      };

      let newsContent;
      this.yukkApi.newsviewNew({ id: params.news, params: params }).subscribe(result => {
        newsContent = JSON.parse(JSON.stringify(result));
        if (eventNames.length > 0) {
        const extractTheEventInformation = newsContent.events.find((event: any) => event.name.trim() === eventNames[0].trim());
        if(extractTheEventInformation) {
          Object.assign(params, { eventid: extractTheEventInformation.id , eventype: extractTheEventInformation.type, newstype: 'Events' });
        }
      }
        this.readonlyStateService.setModalReadonly(true);
        this.dialog.open(NewsViewComponent, {
          autoFocus: false,
          panelClass: 'panelNews',
          disableClose: false,
          closeOnNavigation: true,
          data: {
            newsinfo: newsContent,
            params: params,
          },
        }).afterClosed().subscribe(() => {
          this.readonlyStateService.setModalReadonly(false);
        });
      }, error => {
        this.handleError(error);
      });
    }
    
    // closing or opening chat charts component and storing its state in localStorage


    closeAllCharts() {
      this.chartVisible = false;
      this.saveChartVisibility();
    }
  
    openAllCharts() {
      this.chartVisible = true;
      // console.log(this.chartVisible)
      this.saveChartVisibility();

    }

    updateChartVisibility(isVisible: boolean) {
      this.chartVisible = isVisible;
      this.saveChartVisibility();
    }

    private saveChartVisibility() {
      localStorage.setItem('chartVisible', JSON.stringify(this.chartVisible));
    }

    private loadChartVisibility() {
      const savedVisibility = localStorage.getItem('chartVisible');
      if (savedVisibility !== null) {
        this.chartVisible = JSON.parse(savedVisibility);
      }
    }


    shouldShowChatCharts(chatMessage : AIMessage): boolean {
      return (
        (chatMessage.companyName && 
        chatMessage.companyName !== 'all' && 
        chatMessage.companyName !== '') &&
        chatMessage.recommended_charts?.length > 0 && 
        chatMessage.companyType === 'company'
      );
    }

    shouldShowOpenChartsIcon(chatMessage: AIMessage): boolean {
      return (
        !this.chartVisible &&
        this.shouldShowChatCharts(chatMessage)
      );
    }

    shouldShowEmptyContainer( chatMessage): boolean {
      return (
        (
          (chatMessage.companyName === 'all' || 
          chatMessage.companyName === '') || 
          chatMessage.companyType === 'person' || 
          ! chatMessage.recommended_charts?.length
        ) && this.chartVisible
      );
    }

    
}
