// readonly-state.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalReadonlyStateService {
  private _isModalReadonly = false;

  setModalReadonly(isModalReadonly: boolean): void {
    this._isModalReadonly = isModalReadonly;
  }

  isModalReadonly(): boolean {
    return this._isModalReadonly;
  }
}
