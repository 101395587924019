import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PortfolioNameComponent } from '../news-portfolio/portfolio-name/portfolio-name.component';
import { QueryNameComponent } from '../news-query/query-name/query-name.component';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { ColorPipe } from '../../../pipe/color.pipe';
import { ColorScorePipe } from '../../../pipe/color-score.pipe';
import { ColorScore2Pipe } from '../../../pipe/color-score2.pipe';
import { DialogTableComponent } from '../../../micro-components/dialog-table/dialog-table.component';
import { DialogReportComponent } from '../../../micro-components/dialog-report/dialog-report.component';
import * as moment from 'moment';
import { motherCompany, suppliers } from '../news-network/network-data';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-title',
  templateUrl: './news-title.component.html',
  styleUrls: ['./news-title.component.scss']
})
export class NewsTitleComponent implements OnChanges {

  // @ViewChild('sectorsDialog') sectorsDialog: ElementRef;

  @Input('titolo') titolo: any;
  params: any;
  previousValue: any;
  folios: any;
  folio: any;
  query: any;
  id: string;
  label: string;
  period: number;
  showWarningFlag = false;
  showCautionFlag = false;
  flagScore: number;
  industryBenchmarkScore = null;
  industryBenchmarkDescription = '';
  alertScoreType = null;
  alertScoreValue = null;
  alertEvents = null;
  alertEventsCount = null;
  alertEventsTimeframe = null;
  scoreEvents = null;
  scoreEventsCount = null;
  scoreEventsTimeframe = null;
  flagWarningEvents = null;
  flagWarningEventsCount = null;
  flagWarningEventsTimeframe = null;
  flagCautionEvents = null;
  flagCautionEventsCount = null;
  flagCautionEventsTimeframe = null;

  mouseOverCautionFlag = false;
  mouseOverWarningFlag = false;
  mouseOverIndustryBench = false;
  mouseOverScore = false;
  mouseOverAlert = false;
  isScoreFocused = false;

  entities = [];
  queries = [];

  isReportAvailable = false;
  reportStatus: string;

  /**
   * color theme
   */

  theme = 'dark';

  /**
   * filters on suppliers view
   */
  suppliersFilters: any;
  productFilter = [];
  geographyFilter = [];
  tierFilter = ['Tier 1'];
  scoreFilter = ['0-2', '2-4', '4-6', '6-8', '8-10'];


  // llm chart visualization

  customScore: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public config: ConfigService,
    public dialog: MatDialog,
    private colorpipe: ColorPipe,
    private colorpipeScore: ColorScorePipe,
    private colorpipeScore2: ColorScore2Pipe,
    private cdr: ChangeDetectorRef
  ) {

    this.customScore = this.auth.scorelabSettings.defaultScore;
    this.alertScoreType = null;

    this.suppliersFilters = {
      product: 'All',
      geography: 'All',
      tier: 'All',
      score: 0
    };
    // motherCompany.supplied_products.forEach(product => {
    //   if (!this.productFilter.includes(product)) {
    //     this.productFilter.push(product);
    //   }
    // });
    motherCompany.countries.forEach(country => {
      if (!this.geographyFilter.includes(country)) {
        this.geographyFilter.push(country);
      }
    });
    suppliers.forEach(supplier => {
      supplier.supplied_products.forEach(product => {
        if (!this.productFilter.includes(product)) {
          this.productFilter.push(product);
        }
      });
      supplier.countries.forEach(country => {
        if (!this.geographyFilter.includes(country)) {
          this.geographyFilter.push(country);
        }
      });
    });

    this.route.queryParams.subscribe(params => {

      this.params = params;
      this.period = params.period ? params.period : 7;

      if (params.custom_score) {
        this.customScore = this.params.custom_score;
      } else {
        this.customScore = this.auth.scorelabSettings.defaultScore;
      }
      if (params.theme) {
        this.theme = params.theme;
      } else if (this.config.appConfig.setup.useStorage) {
        this.theme = localStorage.getItem('theme');
      } else {
        this.theme = 'dark';
      }
      if (this.theme === 'yukka') {
        this.theme = 'dark';
      }

      let supFilterScore = 0;
      if (params.sup_score) {
        if (params.sup_score === '0-2') {
          supFilterScore = 1;
        } else if (params.sup_score === '2-4') {
          supFilterScore = 2;
        } else if (params.sup_score === '4-6') {
          supFilterScore = 3;
        } else if (params.sup_score === '6-8') {
          supFilterScore = 4;
        } else if (params.sup_score === '8-10') {
          supFilterScore = 5;
        }
      }

      this.suppliersFilters = {
        product: params.sup_prod ? params.sup_prod : 'All',
        geography: params.sup_geo ? params.sup_geo : 'All',
        tier: params.sup_tier ? params.sup_tier : 'All',
        score: supFilterScore
      };

      if (routing.reFresh(params, this.previousValue, ['id', 'groupId', 'portfolioId'])) {

        if (routing.isFolio()) {
          if (this.auth.folios) {
            const portfolioId = params.portfolioId ? params.portfolioId : params.id;
            this.folio = this.auth.folios.filter(el => {
              return el.uid === portfolioId;
            })[0];
            const entities = this.folio.content;
            this.yukkApi.getEntitiesInfo(entities).subscribe(result => {
              this.entities = Object.values(result).filter(element => {
                return element !== null;
              });
              this.entities.forEach(item => {
                item.name = (item.name !== 'PNE') ? item.name : (item.alpha_id.split('.')[1] + ' (' + item.alpha_id.split('.')[0] + ')');
              });
            });
          } else {
            this.entities = [];
          }
          // this.folios = this.auth.folios;
          // this.folio = this.auth.folio.name;
          // this.id = this.auth.folio.id;
        }

        if (routing.isQuery()) {
          if (this.auth.querys) {
            const collectionId = params.groupId;
            this.query = this.auth.querys.filter(el => {
              return el.uid === collectionId;
            })[0];
            this.queries = this.query.content;
          } else {
            this.queries = [];
          }
          // this.folios = this.auth.querys;
          // this.folio = this.auth.query.name;
          // this.id = this.auth.query.id;
        }

      }

      if (routing.reFresh(params, this.previousValue, ['updateFlag'])) {
        this.checkWarningFlag();
        this.checkCautionFlag();
      }

      if (routing.reFresh(params, this.previousValue, ['focused_score'])) {
        if (params.focused_score && (this.customScore !== params.focused_score)) {
          this.alertScoreType = params.focused_score;
        }
      }

      if (routing.reFresh(params, this.previousValue, ['time'])) {
        if (this.params.score) {
          this.executeOnChanges();
        }
      }

      this.previousValue = params;

    });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.executeOnChanges();
  }

  executeOnChanges() {

    this.isReportAvailable = false;

    if (this.titolo) {
      const date = moment(new Date()).format('YYYY-MM-DD');
      let type = this.customScore;
      if (type === 'credit_risk') {
        type = 'credit_watch';
      }
      if (!this.config.appConfig.setup.reportsDisabled && ['general_risk', 'immediate_risk', 'esg', 'esg_risk', 'eb_sim', 'eb_sim_risk', 'credit_watch'].includes(type)) {
        if (this.titolo?.entity?.type === 'portfolio') {
          const companies = this.folio?.content;
          this.auth.getReportStatusPortfolio(companies, type, date).subscribe(res => {
            this.reportStatus = res['status'];
            if (res['status'] !== 'not_enough_data') {
              this.isReportAvailable = true;
            }
          });
        } else if (this.titolo?.entity?.type === 'company') {
          this.auth.getReportStatus(this.titolo.entity.alpha_id, type, date).subscribe(res => {
            this.reportStatus = res['status'];
            if (res['status'] !== 'not_enough_data') {
              this.isReportAvailable = true;
            }
          });
        }
      }
    }

    this.scoreEventsCount = null;
    this.scoreEventsTimeframe = null;
    this.flagWarningEventsCount = null;
    this.flagWarningEventsTimeframe = null;
    this.flagCautionEventsCount = null;
    this.flagCautionEventsTimeframe = null;
    if (!this.params.score) {
      this.isScoreFocused = false;
    }
    if (this.alertScoreType && (this.customScore === this.alertScoreType)) {
      this.alertScoreType = null;
    }

    this.checkWarningFlag();
    this.checkCautionFlag();
    this.getIndustryBenchmark();
    this.getScoreEvents();
    this.getScore();

    // if (!this.routing.isWidget()) {
    //   this.router.navigate([], {
    //     queryParams: {
    //       reflow: Math.random()
    //     },
    //     queryParamsHandling: 'merge'
    //   });
    // }

    if (this.titolo && this.params.type && (this.params.type === 'pne')) {
      this.titolo.entity.name = this.titolo.entity.alpha_id.split('.')[1] + ' (' + this.titolo.entity.alpha_id.split('.')[0] + ')';
    } else if (this.auth && this.auth.folio && this.auth.folio.marketPortfolio && this.titolo && this.params.type && (['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) {
      this.titolo.entity.type = this.params.type;
      this.titolo.entity.name = this.auth.folio.marketPortfolioName;
    }

    if (this.titolo && this.params && (this.params.focused_score && (this.params.focused_score === this.customScore))) {
      let scoreColor = 'transparent';
      if ((this.params.focused_score === 'sentiment') && this.titolo.sentiment?.sentiment) {
        scoreColor = this.colorpipe.transform(this.titolo.sentiment.sentiment);
      } else if (['bb'].includes(this.params.focused_score) && this.titolo.trend_type) {
        scoreColor = (this.titolo.trend_type === 'bear') ? '#dd001a' : '#1e816c';
      } else if (this.titolo.score && this.auth.getScoreType(this.params.focused_score) === 'pos') {
        scoreColor =  this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.titolo.score) {
        scoreColor =  this.colorpipeScore.transform(this.titolo.score);
      }
      const timeframe = (this.params.focused_score !== 'sentiment') ? this.checkFocusedTimeframe(this.params.time, 100) : (this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7);
      setTimeout(() => {
        this.onScoreFocus('score', this.params.focused_score, timeframe, scoreColor, false);
      }, 100);
    } else if (this.titolo && this.params && (this.params.score && (this.params.score === this.customScore))) {
      let scoreColor = 'transparent';
      if ((this.params.score === 'sentiment') && this.titolo.sentiment?.sentiment) {
        scoreColor = this.colorpipe.transform(this.titolo.sentiment.sentiment);
      } else if (['bb'].includes(this.params.score) && this.titolo.trend_type) {
        scoreColor = (this.titolo.trend_type === 'bear') ? '#dd001a' : '#1e816c';
      } else if (this.titolo.score && this.auth.getScoreType(this.params.score) === 'pos') {
        scoreColor =  this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.titolo.score) {
        scoreColor =  this.colorpipeScore.transform(this.titolo.score);
      }
      const timeframe = (this.params.score !== 'sentiment') ? this.checkFocusedTimeframe(this.params.time, 100) : (this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7);
      setTimeout(() => {
        this.onScoreFocus('score', this.params.score, timeframe, scoreColor, false);
      }, 100);
    } else if (this.alertScoreType && this.titolo && this.params && ((this.params.focused_score && (this.params.focused_score !== this.customScore)) || (this.params.score && (this.params.score !== this.customScore)))) {
      this.getAlert();
      this.getAlertEvents();
    }
  }

  getAlert() {
    if (this.alertScoreType) {
      this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
        requestScore: this.alertScoreType
      }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
        if (['bb'].includes(this.alertScoreType)) {
          this.alertScoreValue = {};
          this.alertScoreValue.score = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.score : null;
          this.alertScoreValue.trend_value = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.trend_value : null;
          this.alertScoreValue.trend_type = res.score_ts_last_score.trend_type;
        } else {
          this.alertScoreValue = (res.score_ts_last_score.score !== null) ? Number(res.score_ts_last_score.score.toFixed(1)) : null;
        }
        // this.alertScoreValue = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.score.toFixed(1) : null;
        let scoreColor = 'transparent';
        if ((this.alertScoreType === 'sentiment') && this.titolo.sentiment?.sentiment) {
          scoreColor = this.colorpipe.transform(this.titolo.sentiment.sentiment);
        } else if (['bb'].includes(this.alertScoreType) && this.alertScoreValue.trend_type) {
          scoreColor = (this.alertScoreValue.trend_type === 'bear') ? '#dd001a' : '#1e816c';
        } else if (this.alertScoreValue && this.auth.getScoreType(this.alertScoreType) === 'pos') {
          scoreColor =  this.colorpipeScore2.transform(this.alertScoreValue);
        } else if (this.alertScoreValue) {
          scoreColor =  this.colorpipeScore.transform(this.alertScoreValue);
        }
        const timeframe = (this.alertScoreType !== 'sentiment') ? this.checkFocusedTimeframe(this.params.time, 100) : (this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7);
        setTimeout(() => {
          this.onScoreFocus('alert', this.alertScoreType, timeframe, scoreColor, false);
        }, 100);

      });
    }
  }

  getAlertEvents() {
    if (this.alertScoreType === 'sentiment') {
      this.yukkApi.relatedEvents(this.params, 'sub_events').subscribe(result => {
        const resCopy = JSON.parse(JSON.stringify(result.events));
        this.setAlertEventsInfo(resCopy);
      });
    } else if (!(this.alertScoreType === 'sentiment') && (!(this.alertScoreType === 'credit_risk') || ((this.alertScoreType === 'credit_risk') && (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))))) && (this.routing.isFolio() || (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) || (this.params.isin))) {
      if (this.alertScoreType === 'credit_risk') {
        this.yukkApi.scoresTimeSeries('score', this.params, 'chart').subscribe(result => {
          const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;
          this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
            with_top_events: true,
            with_all_events: true,
            requestScore: this.alertScoreType,
            time: this.checkFocusedTimeframe(this.params.time, 100),
            top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
          }), 'chart').subscribe(result2 => {
            const resCopy = JSON.parse(JSON.stringify(result2));
            this.setAlertEventsInfo(resCopy);
          });
        });
      } else if (this.alertScoreType === 'bb')  {
        this.yukkApi.scoresTimeSeries('bb_score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          requestScore: this.alertScoreType,
          time: this.checkFocusedTimeframe(this.params.time, 100)
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setAlertEventsInfo(resCopy);
        });
      } else {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          requestScore: this.alertScoreType,
          time: this.checkFocusedTimeframe(this.params.time, 100)
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setAlertEventsInfo(resCopy);
        });
      }
    }
  }

  getScore() {
    if (this.titolo && this.titolo.entity && this.customScore && (this.customScore !== 'sentiment')) {
      this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
        requestScore: this.customScore
      }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
        if (['bb'].includes(this.customScore)) {
          this.titolo.score = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.score : null;
          this.titolo.trend_value = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.trend_value : null;
          this.titolo.trend_type = res.score_ts_last_score.trend_type;
        } else {
          this.titolo.score = (res.score_ts_last_score.score !== null) ? Number(res.score_ts_last_score.score.toFixed(1)) : null;
        }
        // this.titolo.score = (res.score_ts_last_score.score !== null) ? res.score_ts_last_score.score.toFixed(1) : null;
      });
    }
  }

  getScoreEvents() {
    if (this.customScore === 'sentiment') {
      this.yukkApi.relatedEvents(this.params, 'sub_events').subscribe(result => {
        const resCopy = JSON.parse(JSON.stringify(result.events));
        this.setScoreEventsInfo(resCopy);
      });
    } else if (!(this.customScore === 'sentiment') && (!(this.customScore === 'credit_risk') || ((this.customScore === 'credit_risk') && (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))))) && (this.routing.isFolio() || (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) || (this.params.isin))) {
      if (this.customScore === 'credit_risk') {
        this.yukkApi.scoresTimeSeries('score', this.params, 'chart').subscribe(result => {
          const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;
          this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
            with_top_events: true,
            with_all_events: true,
            time: this.checkFocusedTimeframe(this.params.time, 100),
            top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
          }), 'chart').subscribe(result2 => {
            const resCopy = JSON.parse(JSON.stringify(result2));
            this.setScoreEventsInfo(resCopy);
          });
        });
      } else if (this.customScore === 'bb') {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          time: this.checkFocusedTimeframe(this.params.time, 100)
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setScoreEventsInfo(resCopy);
        });
      } else {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          time: this.checkFocusedTimeframe(this.params.time, 100)
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setScoreEventsInfo(resCopy);
        });
      }
    }
  }

  getFlagEvents(type) {
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      this.yukkApi.relatedEvents(Object.assign({}, this.params, {
        time: ((type === 'warning') || ((type === 'caution') && !this.checkLongTimeframe())) ? (this.params.time ? this.params.time : null) : this.checkFocusedTimeframe(this.params.time, 365)
      }), 'sub_events').subscribe(result => {
        const resCopy = JSON.parse(JSON.stringify(result.events));
        this.setFlagEventsInfo(resCopy, type);
      });
    } else {
      let time = null;
      if (type === 'warning') {
        time = this.checkFocusedTimeframe(this.params.time, 100);
      } else if (type === 'caution') {
        time = this.checkFocusedTimeframe(this.params.time, 465);
      }
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type
        }), 'chart').subscribe(result => {
          const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;
          this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
            with_top_events: true,
            with_all_events: true,
            requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
            time: time,
            top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
          }), 'chart').subscribe(result2 => {
            const resCopy = JSON.parse(JSON.stringify(result2));
            this.setFlagEventsInfo(resCopy, type);
          });
        });
      } else if (this.auth.scorelabSettings.defaultFlag.score_type === 'bb') {
        this.yukkApi.scoresTimeSeries('bb_score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
          time: time
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setFlagEventsInfo(resCopy, type);
        });
      } else {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
          time: time
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setFlagEventsInfo(resCopy, type);
        });
      }
    }
  }

  setAlertEventsInfo(scoreEventsObj) {
    this.alertEvents = JSON.parse(JSON.stringify(scoreEventsObj));
    if (this.alertScoreType === 'sentiment') {
      let count = 0;
      scoreEventsObj.forEach(event => {
        count += event.count;
      });
      this.alertEventsCount = count;
      this.alertEventsTimeframe = this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7;
    } else {
      let count = 0;
      scoreEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      this.alertEventsCount = count;
      this.alertEventsTimeframe = this.yukkApi.myFromTo(this.checkFocusedTimeframe(this.params.time, 100)).period;
    }
  }

  setScoreEventsInfo(scoreEventsObj) {
    this.scoreEvents = JSON.parse(JSON.stringify(scoreEventsObj));
    if (this.customScore === 'sentiment') {
      let count = 0;
      scoreEventsObj.forEach(event => {
        count += event.count;
      });
      this.scoreEventsCount = count;
      this.scoreEventsTimeframe = this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7;
    } else {
      let count = 0;
      scoreEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      this.scoreEventsCount = count;
      this.scoreEventsTimeframe = this.yukkApi.myFromTo(this.checkFocusedTimeframe(this.params.time, 100)).period;
    }
  }

  setFlagEventsInfo(flagEventsObj, flagType) {
    if (flagType === 'warning') {
      this.flagWarningEvents = JSON.parse(JSON.stringify(flagEventsObj));
    } else if (flagType === 'caution') {
      this.flagCautionEvents = JSON.parse(JSON.stringify(flagEventsObj));
    }
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      let count = 0;
      flagEventsObj.forEach(event => {
        count += event.count;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
        this.flagWarningEventsTimeframe = this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
        this.flagCautionEventsTimeframe = this.checkLongTimeframe() ? this.checkFocusedTimeframe(this.params.time, 365) : (this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7);
      }
    } else {
      let count = 0;
      flagEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
      }
      if (flagType === 'warning') {
        this.flagWarningEventsTimeframe = this.yukkApi.myFromTo(this.checkFocusedTimeframe(this.params.time, 100)).period;
      } else if (flagType === 'caution') {
        this.flagCautionEventsTimeframe = this.yukkApi.myFromTo(this.checkFocusedTimeframe(this.params.time, 465)).period;
      }
    }
  }

  getIndustryBenchmark() {

    if ((['esg', 'eb_sim', 'eb_sim_risk', 'general_risk', 'esg_risk', 'immediate_risk', 'credit_risk', 'bb'].includes(this.customScore)) && (this.params && (this.params.type === 'company'))) {
      this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
        requestScore: this.customScore,
        industry_bench: true
      }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
        let industry = 'Industry';
        if (this.titolo?.entity?.description?.Industry) {
          industry = this.titolo.entity.description.Industry.name;
        }
        this.industryBenchmarkScore = res.score_ts_last_score.score;
        if (res.score_ts_last_score.score === 0.0) {
          this.industryBenchmarkDescription = 'Laggard';
        } else if (res.score_ts_last_score.score === 1.0) {
          this.industryBenchmarkDescription = 'Below average';
        } else if (res.score_ts_last_score.score === 2.0) {
          this.industryBenchmarkDescription = 'Above average';
        } else if (res.score_ts_last_score.score === 3.0) {
          this.industryBenchmarkDescription = 'Leader';
        }
        this.industryBenchmarkDescription += ' in ' + industry;
      });
    } else {
      this.industryBenchmarkScore = null;
      this.industryBenchmarkDescription = '';
    }

  }

  checkWarningFlag() {

    if (this.titolo && this.titolo.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(this.titolo.entity.type)) {
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        const currentScore = this.titolo.sentiment.sentiment * 100;
        if (currentScore) {
          this.flagScore = currentScore;
          if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
            if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
              this.showWarningFlag = true;
              this.auth.showWarningFlag = true;
              this.auth.showWarningFlagLoader();
              this.getFlagEvents('warning');
            } else {
              this.showWarningFlag = false;
              this.auth.showWarningFlag = false;
              this.auth.hideWarningFlagLoader();
            }
          } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
            if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
              this.showWarningFlag = true;
              this.auth.showWarningFlag = true;
              this.auth.showWarningFlagLoader();
              this.getFlagEvents('warning');
            } else {
              this.showWarningFlag = false;
              this.auth.showWarningFlag = false;
              this.auth.hideWarningFlagLoader();
            }
          }
        } else {
          this.showWarningFlag = false;
          this.auth.showWarningFlag = false;
          this.auth.hideWarningFlagLoader();
        }
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type
        }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
          const currentScore = res.score_ts_last_score.score;
          if (currentScore) {
            this.flagScore = currentScore;
            if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
              if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                this.showWarningFlag = true;
                this.auth.showWarningFlag = true;
                this.auth.showWarningFlagLoader();
                this.getFlagEvents('warning');
              } else {
                this.showWarningFlag = false;
                this.auth.showWarningFlag = false;
                this.auth.hideWarningFlagLoader();
              }
            } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
              if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                this.showWarningFlag = true;
                this.auth.showWarningFlag = true;
                this.auth.showWarningFlagLoader();
                this.getFlagEvents('warning');
              } else {
                this.showWarningFlag = false;
                this.auth.showWarningFlag = false;
                this.auth.hideWarningFlagLoader();
              }
            }
          } else {
            this.showWarningFlag = false;
            this.auth.showWarningFlag = false;
            this.auth.hideWarningFlagLoader();
          }
        });
      } else {
        this.showWarningFlag = false;
        this.auth.showWarningFlag = false;
        this.auth.hideWarningFlagLoader();
      }
    } else {
      this.showWarningFlag = false;
      this.auth.showWarningFlag = false;
      this.auth.hideWarningFlagLoader();
    }

  }

  checkCautionFlag() {

    if (this.titolo && this.titolo.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(this.titolo.entity.type)) {
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        this.yukkApi.scoresTimeSeries('sentiment', Object.assign({}, this.params, {
          time: this.checkLongTimeframe() ? this.checkFocusedTimeframe(this.params.time, 365) : (this.params.time ? this.params.time : null)
        }), '').pipe(catchError(error => of({quotient_ts: []}))).subscribe(res => {
          let thresholdReached = false;
          res.quotient_ts.forEach(item => {
            const currentScore = item.quotient * 100;
            if (currentScore) {
              if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
                if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
                if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              }
            }
          });
          if (thresholdReached) {
            this.showCautionFlag = true;
            this.auth.showCautionFlag = true;
            this.auth.showCautionFlagLoader();
            this.getFlagEvents('caution');
          } else {
            this.showCautionFlag = false;
            this.auth.showCautionFlag = false;
            this.auth.hideCautionFlagLoader();
          }
        });
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
          time: this.checkFocusedTimeframe(this.params.time, 365)
        }), 'chart').pipe(catchError(error => of({score_ts: []}))).subscribe(res => {
          let thresholdReached = false;
          res.score_ts.forEach(item => {
            const currentScore = item.score;
            if (currentScore) {
              if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
                if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
                if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              }
            }
          });
          if (thresholdReached) {
            this.showCautionFlag = true;
            this.auth.showCautionFlag = true;
            this.auth.showCautionFlagLoader();
            this.getFlagEvents('caution');
          } else {
            this.showCautionFlag = false;
            this.auth.showCautionFlag = false;
            this.auth.hideCautionFlagLoader();
          }
        });
      } else {
        this.showCautionFlag = false;
        this.auth.showCautionFlag = false;
        this.auth.hideCautionFlagLoader();
      }
    } else {
      this.showCautionFlag = false;
      this.auth.showCautionFlag = false;
      this.auth.hideCautionFlagLoader();
    }
  }

  getFlagTooltip(type) {
    const threshold = this.auth.scorelabSettings.defaultFlag.threshold;
    const scoreName = this.auth.getScoresInfo(this.auth.scorelabSettings.defaultFlag.score_type)['full'];
    let score;
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      score = this.flagScore + ' of the ' + scoreName;
    } else {
      score = this.flagScore?.toFixed(2);
    }
    let tooltip = '';
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      if (type === 'warning') {
        tooltip = 'Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
      } else if (type === 'caution') {
        tooltip = 'One of the ' + scoreName + ' values in the past year crossed the set threshold of ' + threshold + '.';
      }
    } else {
      if (type === 'warning') {
        tooltip = 'One or more of the events associated with ' + scoreName + ' occurred in the past 100 days. Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
      } else if (type === 'caution') {
        tooltip = 'One or more of the events associated with ' + scoreName + ' occurred in the past 465 days. One of the values in the past year crossed the set threshold of ' + threshold + '.';
      }
    }
    return {
      tooltip: tooltip,
      score_type: this.auth.scorelabSettings.defaultFlag.score_type,
      score_name: this.auth.getScoresInfo(this.auth.scorelabSettings.defaultFlag.score_type)['name'],
      score_threshold: (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') ? 'High' : 'Low'
    };
  }

  // inFolio(value) {
  //   this.router.navigate([], {
  //     queryParams: {
  //       id: this.folios.filter(folio => folio.name === value)[0].uid
  //     },
  //     queryParamsHandling: 'merge'
  //   });
  // }

  displayFn(element) {
    return element && element.name ? element.name : '';
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(item => item.name.toLowerCase().includes(filterValue));
  }

  inAverage() {
    if (this.period > 200) { this.period = 200; }
    if (this.period < 1) { this.period = 1; }
    this.router.navigate([], {
      queryParams: {
        period: this.period
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  select(item) {
    if (item === 'overall') {
      if (this.routing.isFolio()) {
        this.titolo.entity.type = 'Portfolio';
        this.titolo.entity.name = this.folio.name;
        this.router.navigate([], {
          queryParams: {
            id: this.params.portfolioId ? this.params.portfolioId : this.params.id,
            type: null,
            newstype: null,
            portfolioId: null,
            score: null,
            time: this.params.score ? null : this.params.time,
            reflow: Math.random(),
            focused_score: this.customScore ? this.customScore : null
          },
          queryParamsHandling: 'merge'
        });
      } else if (this.routing.isQuery()) {
        this.titolo.entity.type = 'Collection';
        this.titolo.entity.name = this.query.name;
        this.router.navigate([], {
          queryParams: {
            id: this.params.groupId,
            newstype: null,
            groupId: this.params.groupId,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      }
    } else {
      if (this.routing.isFolio()) {
        this.titolo.entity.type = item.type;
        this.titolo.entity.name = item.name;
        this.router.navigate([], {
          queryParams: {
            id: item.alpha_id,
            type: item.type,
            newstype: null,
            portfolioId: this.params.portfolioId ? this.params.portfolioId : this.params.id,
            score: null,
            time: this.params.score ? null : this.params.time,
            reflow: Math.random(),
            focused_score: this.customScore ? this.customScore : null
          },
          queryParamsHandling: 'merge'
        });
      } else if (this.routing.isQuery()) {
        this.titolo.entity.type = 'Query';
        this.titolo.entity.name = item.name;
        this.router.navigate([], {
          queryParams: {
            id: item.uid,
            newstype: null,
            groupId: this.params.groupId,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      }
    }
  }

  onScoreFocus(type, score, timeframe, element, onClick) {

    if (!this.isScoreFocused || (this.params && this.params.score && (score !== this.params.score))) {
      let focusedColor = 'transparent';
      if (typeof element === 'string') {
        focusedColor = element;
      } else if (element) {
        focusedColor = window.getComputedStyle(element).getPropertyValue('border-bottom-color');
      }
      this.auth.scoreFocusedColor = focusedColor;
      this.auth.scoreFocusedTimeframe = timeframe;
      if (type === 'score') {
        this.auth.scoreFocusedTopEvents = this.scoreEvents;
      } else if (type === 'warning') {
        this.auth.scoreFocusedTopEvents = this.flagWarningEvents;
      } else if (type === 'caution') {
        this.auth.scoreFocusedTopEvents = this.flagCautionEvents;
      } else if (type === 'alert') {
        this.auth.scoreFocusedTopEvents = this.alertEvents;
      }
      this.auth.scoreFocusedType = type;
      this.isScoreFocused = true;

      this.router.navigate([], {
        queryParams: {
          focused_score: null,
          score: score,
          time: timeframe,
          reflow: Math.random()
        },
        queryParamsHandling: 'merge'
      });
    } else if (!onClick && this.isScoreFocused && this.params && this.params.score && (score === this.params.score) && this.params.time && this.checkFocusedTimeframe(this.params.time, null)) {
      let focusedColor = 'transparent';
      if (typeof element === 'string') {
        focusedColor = element;
      } else if (element) {
        focusedColor = window.getComputedStyle(element).getPropertyValue('border-bottom-color');
      }
      this.auth.scoreFocusedColor = focusedColor;
      this.auth.scoreFocusedTimeframe = this.checkFocusedTimeframe(this.params.time, null);
      if (type === 'score') {
        this.auth.scoreFocusedTopEvents = this.scoreEvents;
      } else if (type === 'warning') {
        this.auth.scoreFocusedTopEvents = this.flagWarningEvents;
      } else if (type === 'caution') {
        this.auth.scoreFocusedTopEvents = this.flagCautionEvents;
      } else if (type === 'alert') {
        this.auth.scoreFocusedTopEvents = this.alertEvents;
      }
      this.auth.scoreFocusedType = type;
      this.isScoreFocused = true;
    } else {
      this.auth.scoreFocusedColor = 'transparent';
      this.auth.scoreFocusedTimeframe = null;
      this.auth.scoreFocusedTopEvents = null;
      this.auth.scoreFocusedType = null;
      this.isScoreFocused = false;
      this.router.navigate([], {
        queryParams: {
          score: null,
          time: null,
          reflow: Math.random()
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  appendItem(option) {
    if (this.routing.isMarket()) {
      this.dialog.open(PortfolioNameComponent, {
        data: {
          action: option,
          entity: this.titolo.entity
        }
      });
    }
    if (this.routing.isSearch()) {
      this.dialog.open(QueryNameComponent, {
        data: {
          action: option,
          query: this.titolo.entity
        }
      });
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkTimeframe() {
    if (this.params.time) {
      const days = this.yukkApi.myFromTo(this.params.time).period;
      if (days < 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe() {
    if (this.params.time) {
      const days = this.yukkApi.myFromTo(this.params.time).period;
      if (days < 365) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkFocusedTimeframe(time, timeframe) {
    let days = null;
    if (time) {
      days = this.yukkApi.myFromTo(time).period;
    }
    if (this.params?.score && ['sentiment'].includes(this.params.score)) {
      if (days) {
        return time;
      } else {
        return 7;
      }
    } else if (days === 100) {
      return time;
    } else if (days === 365) {
      return time;
    } else if (days === 465) {
      return time;
    } else if (timeframe === 100) {
      return 100;
    } else if (timeframe === 365) {
      return 365;
    } else if (timeframe === 465) {
      return 465;
    } else {
      return null;
    }
  }

  /**
   * open popup dialog with sanctions table
   */
  openDialog(entity) {
    this.dialog.open(DialogTableComponent, {
      data: {
        // uid: this.params.folio,
        title: 'Sanctions on ' + entity.name,
        table: entity.sanctions.map(sanction => {
          return {
            authority: sanction.sanctioned_by.name,
            authorityId: sanction.sanctioned_by.alpha_id,
            authorityType: sanction.sanctioned_by.type,
            program: sanction.program,
            start: sanction.sanctioned_from,
            end: sanction.sanctioned_to
          };
        })
      },
      width: '60%'
    });
  }

  /**
   * open popup dialog with reports details
   */
  openReportDialog(entity) {
    if (entity.type === 'portfolio') {
      this.dialog.open(DialogReportComponent, {
        data: {
          type: this.customScore,
          entityId: entity.alpha_id,
          portfolioName: entity.name,
          portfolioContent: this.entities.map(c => c.compound_key).filter(e => e.split(':')[0] === 'company'),
          titleLine1: entity.name,
          titleLine2: this.auth.getScoreName().name + ' Portfolio Report',
          messageLine: (this.auth.folio.content.filter(e => e.split(':')[0] === 'company').length !== this.auth.folio.content.length) ? 'Your portfolio includes entities other than companies that won’t be covered by the report.' : null,
          status: this.reportStatus
        }
      });
    } else {
      this.dialog.open(DialogReportComponent, {
        data: {
          type: this.customScore,
          entityId: entity.alpha_id,
          titleLine1: entity.name,
          titleLine2: this.auth.getScoreName().name + ' Report',
          status: this.reportStatus
        }
      });
    }
  }

  /**
   * filtering suppliers
   */
  filterSuppliers(value, type) {
    if (type === 'product') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_prod: (value === 'All') ? null : value
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else if (type === 'geography') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_geo: (value === 'All') ? null : value
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else if (type === 'tier') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_tier: (value === 'All') ? null : value
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else if (type === 'score') {
      const label = this.sliderLabel(value);
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_score: (label === 'All') ? null : label
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  /**
   * formatting label to be displayed for different score range values
   */
  sliderLabel(value: number): string {
    if (value === 0) {
      return 'All';
    } else if (value === 1) {
      return '0-2';
    } else if (value === 2) {
      return '2-4';
    } else if (value === 3) {
      return '4-6';
    } else if (value === 4) {
      return '6-8';
    } else if (value === 5) {
      return '8-10';
    }
    return `${value}`;
  }

  protected readonly motherCompany = motherCompany;
}
