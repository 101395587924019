<div  [ngClass]="{'hidden': !isVisible}" class="charts__container" >
    <div class="charts__header--container">
        <div class="charts__header">
        <div class="charts__title"><span  class="charts__company">{{chat.message.entityName}}</span>, {{trendScoreFormattedName}} Score Analysis</div>
        <div class="charts__timeframe">Last week</div>
        </div>
        <mat-icon class="charts__icon--close" id="chat-close-charts" (click)="closeCharts()">close</mat-icon>
    </div>

    <div>
        
  
    <app-chat-charts-trend *ngIf="showTrend" [childTrend]="chat.message.companyName" [childTrendScore]="trendScoreType"></app-chat-charts-trend>
    <app-chat-charts-events  *ngIf="showEvents" [childEvent]="chat.message.companyName" [childEventScore]="eventsScoreType"></app-chat-charts-events>
    <!-- <app-chat-charts-ranking *ngIf="showRanking" [childRanking]="chat.message.companyName"></app-chat-charts-ranking> -->
    <!-- <app-chat-charts-benchmark *ngIf="showEvents" [childBenchmark]="chat.message.companyName"></app-chat-charts-benchmark> -->
    </div>
</div>

