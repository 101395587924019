<div class="viewnews">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div mat-dialog-title class="newsmenu" *ngIf="!isModalReadonly && (!apicall || routing.isReader())">
    <div>
      <button *ngIf="!routing.isReader()" mat-raised-button class="navigo" (click)="iNavigo('previus')" [disabled]="iDisable('previus')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button *ngIf="!routing.isReader()" mat-raised-button class="navigo" (click)="iNavigo('next')" [disabled]="iDisable('next')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <div>
      <button *ngIf="auth.featureFlags.copyingArticleLinkEnabled && !routing.isReader() && !routing.isStoryReader()" mat-icon-button matTooltip="Copy link to the article" matTooltipPosition="below" (click)="getNewsReaderLink()">
        <mat-icon>link</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Flag" matTooltipPosition="below" *ngIf="auth.featureFlags.flaggingEnabled && !routing.isReader() && !routing.isStoryReader() && (params.newstype !== 'Flagged') && (params.newstype !== 'Dismissed') && !routing.isMobile()" (click)="toggleView('audit')">
        <mat-icon [class.starred]="auditView">flag</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Dismiss" matTooltipPosition="below" *ngIf="auth.featureFlags.dismissingEnabled && !routing.isReader() && !routing.isStoryReader() && (params.newstype !== 'Flagged') && (params.newstype !== 'Dismissed') && !routing.isMobile()" (click)="toggleView('dismiss')">
        <mat-icon [class.starred]="dismissView">remove_circle</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Resolve" matTooltipPosition="below" *ngIf="!routing.isReader() && !routing.isStoryReader() && (data && data.currentnews && data.currentnews.resolved === 'Opened')" (click)="toggleView('resolve')">
        <mat-icon [class.starred]="resolveView">assignment_turned_in</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Check" matTooltipPosition="below" *ngIf="!routing.isReader() && !routing.isStoryReader() && (params.newstype === 'Dismissed') || (data && data.currentnews && data.currentnews.resolved === 'Resolved')" (click)="toggleView('check')">
        <mat-icon [class.starred]="checkView">assignment_returned</mat-icon>
      </button>
      <button *ngIf="auth.featureFlags.bookmarkingEnabled && !routing.isReader() && !routing.isStoryReader()" mat-icon-button matTooltip="{{!iBookmark(newsinfo.id) ? 'Bookmark' : 'Unbookmark'}}" matTooltipPosition="below" (click)="addStar(newsinfo.id)">
        <mat-icon [class.starred]="iBookmark(newsinfo.id)">bookmark</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Annotation" matTooltipPosition="below" (click)="addNote()"
        *ngIf="auth.featureFlags.annotationsEnabled && !routing.isReader() && !routing.isStoryReader() && !params.query">
        <mat-icon>add_comment</mat-icon>
      </button>
      <button mat-icon-button (click)="toggleView('report')" matTooltip="Report" matTooltipPosition="below" *ngIf="auth.featureFlags.reportingEnabled && !newsinfo.reduce_visibility && !routing.isReader()">
        <mat-icon [class.starred]="reportView">report</mat-icon>
      </button>
      <button *ngIf="auth.featureFlags.reportingEnabled && !newsinfo.reduce_visibility && routing.isReader()" mat-icon-button matTooltip="Report" matTooltipPosition="below" (click)="goReport()">
        <mat-icon>report</mat-icon>
      </button>
      <button mat-icon-button (click)="openHelp()" matTooltip="Help" matTooltipPosition="below" *ngIf="auth.featureFlags.showHelp && !newsinfo.reduce_visibility">
        <mat-icon>help</mat-icon>
      </button>
      <button *ngIf="!routing.isReader()" mat-icon-button matTooltip="Close" matTooltipPosition="below" (click)="iClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-actions *ngIf="isModalReadonly" class="mat-dialog-close-action">
    <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  </mat-dialog-actions>

  <div mat-dialog-content class="newscontent" *ngIf="!loading && newsinfo" [class.reader]="routing.isReader()">

    <div class="infomain">

      <div class="info-title">

        <div class="main-blok news-header" *ngIf="!newsinfo.reduce_visibility">
          <div class="info">
            <div class="main-tito hide">Article</div>
            <div class="cont title" [innerHTML]="newsinfo.title | htmlTrik | highlight:'title':mytag | sanitizeHtml"></div>
          </div>
          <div class="subtit">
            <div class="cont">
              <mat-icon>public</mat-icon>
              <span>
            <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer"
               *ngIf="newsinfo.read_more_link">{{newsinfo.provider}}</a>
            <div *ngIf="!newsinfo.read_more_link">{{newsinfo.provider}}</div>
          </span>
            </div>
            <div class="cont">
              <mat-icon>access_time</mat-icon>
              <span>{{newsinfo.publish_time|date:'dd.MM.yyyy HH:mm':'GMT'}} GMT,
                {{newsinfo.publish_time|amTimeAgo}}</span>
            </div>
            <div class="cont shrinkable" *ngIf="newsinfo.document_category" [matTooltip]="newsinfo.document_category ? newsinfo.document_category.name.join(', ') : ''">
              <!--<mat-icon>{{newsinfo.news_type|newstype}}</mat-icon>-->
              <mat-icon>dvr</mat-icon>
              <span>{{(newsinfo.document_category ? newsinfo.document_category.name[0] : '')}}</span>
              <span class="show-more" *ngIf="newsinfo.document_category.name?.length > 1">{{'+' + (newsinfo.document_category.name.length - 1)}}</span>
            </div>
          </div>
        </div>

        <div class="main-blok main-match news-moreinfo">
          <div class="info relate" *ngIf="!routing.isReport()">
            <div class="main-tito">
              <mat-icon (click)="moreinfo=!moreinfo" *ngIf="!moreinfo">arrow_drop_down</mat-icon>
              <mat-icon (click)="moreinfo=!moreinfo" *ngIf="moreinfo">arrow_drop_up</mat-icon>
              <span (click)="moreinfo=!moreinfo">MATCHES, EVENTS & QUERY HITS</span>
              <div class="line" (click)="$event.stopPropagation()"></div>
            </div>
          </div>
        </div>

        <div class="main-blok news-matches" *ngIf="moreinfo && (!apicall || routing.isReader())">
          <div class="subtit">

            <mat-form-field appearance="fill" *ngIf="matches && (matches.length > 0)">
              <mat-label>Entities</mat-label>
              <mat-select [(ngModel)]="selectedMatch" (selectionChange)="onMatchSelection($event)">
                <ng-container *ngFor="let items of matches | groupBy: ['entity.type'] | orderByType | pairs">
                  <mat-optgroup *ngIf="items[0] === 'company'" [label]="items[0] | nicetxt">
                    <mat-option *ngFor="let option of items[1]" [value]="option.entity.alpha_id" [style.color]="option.sentiment | color">{{option.entity.name}}</mat-option>
                  </mat-optgroup>
                </ng-container>
                <ng-container *ngFor="let items of matches | groupBy: ['entity.type'] | orderByType | pairs">
                  <mat-optgroup *ngIf="items[0] === 'person'" [label]="items[0] | nicetxt">
                    <mat-option *ngFor="let option of items[1]" [value]="option.entity.alpha_id" [style.color]="option.sentiment | color">{{option.entity.name}}</mat-option>
                  </mat-optgroup>
                </ng-container>
                <ng-container *ngFor="let items of matches | groupBy: ['entity.type'] | orderByType | pairs">
                  <mat-optgroup *ngIf="(items[0] !== 'company') && (items[0] !== 'person')" [label]="items[0] | nicetxt">
                    <mat-option *ngFor="let option of items[1]" [value]="option.entity.alpha_id" [style.color]="option.sentiment | color">{{option.entity.name}}</mat-option>
                  </mat-optgroup>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="events && (events.length > 0)">
              <div class="space"></div>
              <div class="space"></div>
              <mat-form-field appearance="fill">
                <mat-label>Events</mat-label>
                <mat-select [(ngModel)]="selectedEvent" (selectionChange)="onEventSelection($event)">
                  <mat-option *ngFor="let event of events | orderBy: 'name'" [value]="event.id">{{event.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="space"></div>
              <div *ngIf="selectedEvent && (selectedEventInstance !== null)" class="events-navigation">
            <span (click)="navEvent('previous')">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </span>
                <span><b>{{navEventInfo().current}}</b>{{' / ' + navEventInfo().total}}</span>
                <span (click)="navEvent('next')">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </span>
              </div>
              <div class="space"></div>
              <ng-container *ngIf="mytag?.participants">
                <div class="events-info">
                  <ng-container *ngIf="mytag.participants.length>0">
                    <div class="matches">
                      <div class="onmatch">
                        <div class="inmatch">
                          <mat-icon matTooltip="Event Participants">group</mat-icon>
                        </div>
                        <div *ngFor="let item of mytag.participants" class="inmatch">
                          <div class="btnmatch">
                            <div class="tito">{{item.name}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="main-info">
                    <mat-chip-list>
                      <mat-chip [ngClass]="{
                        'temporality-past2': (mytag?.temporality === 'PAST'),
                        'temporality-present2': (mytag?.temporality === 'PRESENT'),
                        'temporality-future2': (mytag?.temporality === 'FUTURE'),
                      }" *ngIf="mytag?.temporality && (mytag?.temporality !== 'NONE') && (mytag?.temporality !== 'UNKNOWN')" matTooltip="Event Temporality">
                        <mat-icon>access_time</mat-icon>
                        <span>{{mytag?.temporality}}</span>
                      </mat-chip>
                      <mat-chip [ngClass]="{
                        'factuality-fact2': ['FACT', 'COUNTERFACT'].includes(mytag?.factuality),
                        'factuality-probable2': ['PROBABLE', 'COUNTERPROBABLE'].includes(mytag?.factuality),
                        'factuality-possible2': ['POSSIBLE', 'COUNTERPOSSIBLE'].includes(mytag?.factuality),
                      }" *ngIf="mytag?.factuality && (mytag?.factuality !== 'NONE') && (mytag?.factuality !== 'UNKNOWN')" matTooltip="Event Factuality">
                        <mat-icon>assignment_turned_in</mat-icon>
                        <span *ngIf="['FACT', 'COUNTERFACT'].includes(mytag?.factuality)">FACT</span>
                        <span *ngIf="['PROBABLE', 'COUNTERPROBABLE'].includes(mytag?.factuality)">PROBABLE</span>
                        <span *ngIf="['POSSIBLE', 'COUNTERPOSSIBLE'].includes(mytag?.factuality)">POSSIBLE</span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>

        <div class="main-blok main-match news-highlights" *ngIf="moreinfo && params && params.highlight">
          <div class="info relate">
            <div class="main-tito">
              <span>HIGHLIGHTS</span>
              <div class="line"></div>
            </div>
            <div class="matches">
              <div class="onmatch">
                <div class="inmatch">
                  <div class="btnmatch">
                    <!--<div class="tito" [class.btnact]="showHighlights" (click)="iHighlight()">Show query hit spans</div>-->
                    <button mat-stroked-button class="matches" [class.btnfoc]="showHighlights" (click)="iHighlight()">Show query hit spans</button>
                  </div>
                  <div class="btnmatch" *ngIf="auth.featureFlags.gettingHighlightsEnabled && (auth.userdata.roles?.includes('ADMIN') || auth.userdata.groups?.includes('ADMIN') || newsinfo.document_categorization.is_licensed_content)">
                    <!--<div class="tito" [class.btnact]="true" (click)="getHighlightLink()">Get highlights link</div>-->
                    <button mat-stroked-button class="matches" (click)="getHighlightLink()">Get highlights link</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="info-content">

        <div class="main-blok" *ngIf="!newsinfo.reduce_visibility">
          <div class="main-tito hide">Content</div>
          <div class="info">
            <div *ngIf="newsinfo.content" class="cont content" [innerHTML]="newsinfo.content | htmlTrik | highlight:'content':mytag | paragraph | sanitizeHtml"></div>
            <div class="copyright" *ngIf="newsinfo.provider=='businesswire.com'">(c) {{newsinfo.publish_time|date:'yyyy'}}
              Business Wire, Inc. All Rights
              Reserved.</div>
            <button mat-button class="readmore" *ngIf="newsinfo.read_more_link">
              <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer">Read more...</a>
              <!--<mat-icon>link</mat-icon>-->
              <mat-icon matTooltip="Copy link to the original article" matTooltipPosition="below" (click)="getNewsOriginalLink(newsinfo.read_more_link)">link</mat-icon>
            </button>
          </div>
        </div>

        <div class="main-blok" *ngIf="newsinfo.reduce_visibility">
          <div class="main-tito">Article</div>
          <blockquote>
            <p><strong>Due to copyright and/or license restrictions, we are unable to show the original text.</strong></p>
            <p>&mdash;<i>We apologize for any inconvenience.</i></p>
          </blockquote>
          <button mat-button class="readmore" *ngIf="newsinfo.read_more_link">
            <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer">Read more...</a>
            <!--<mat-icon>link</mat-icon>-->
            <mat-icon matTooltip="Copy link to the original article" matTooltipPosition="below" (click)="getNewsOriginalLink(newsinfo.read_more_link)">link</mat-icon>
          </button>
        </div>

        <div *ngIf="!isModalReadonly && newsinfo && (routing.isDev() || routing.isReport() || (user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN')))">
          <br>
          <div class="main-blok">
            <div class="info">
              <div class="main-tito">Matches</div>
              <div class="blok">
                <div class="item match" *ngFor="let match of matches; let i=index" (click)="clickRelate(match)"
                     [class.active]="mytag==match" [style.color]="match.sentiment | color">{{(match.entity.name) ? match.entity.name : match.entity.alpha_id}}</div>
              </div>
            </div>
          </div>
          <div class="main-blok tags" *ngIf="newsinfo.events.length>0">
            <div class="info">
              <div class="main-tito">Events</div>
              <div class="blok">
                <div class="item tag" *ngFor="let event of newsinfo.events;index as index"
                     [class.btnact]="isActiveEvent(index, event.id)" (click)="onEventSelection2(index)">{{event.name}}</div>
              </div>
            </div>
          </div>
          <div class="main-blok tags">
            <div class="info">
              <div class="main-tito">Tags</div>
              <div class="blok">
                <div class="item tag" *ngFor="let item of newsinfo.tags; let i=index"
                     (click)="clickTag(item)" [class.active]="mytag==item">{{item.word}}</div>
              </div>
            </div>
          </div>
          <div class="main-blok" *ngIf="(user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN'))">
            <button mat-button class="readmore" (click)="apiref=!apiref">
              <span>API Reference</span>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <pre *ngIf="apiref">{{newsinfo|json}}</pre>
          </div>
        </div>

      </div>

    </div>

    <div class="auditmain" *ngIf="( auditView || dismissView || resolveView || checkView )">

      <div class="main-blok header">
        <div class="info">
          <div class="cont title" *ngIf="!(resolveView || checkView)">{{(auditView)?"Flag for Action":"Dismiss News"}}</div>
          <div class="cont title" *ngIf="resolveView">{{"Resolve case"}}</div>
          <div class="cont title" *ngIf="checkView">{{(params.newstype === 'Flagged')?"Check resolved case":"Check dismissed case"}}</div>
        </div>
      </div>

      <div class="main-blok content">

        <div class="info">
          <div class="main-tito" *ngIf="!(resolveView || checkView)">Choose entities for news {{(auditView)?"flag":"dismiss"}} (required)</div>
          <div class="main-tito" *ngIf="(resolveView || checkView)">Entities chosen for news {{(params.newstype === 'Flagged')?"flag":"dismiss"}}</div>
          <div class="blok" *ngIf="!(resolveView || checkView)">
            <div class="item tag" *ngFor="let entity of auditEntities; let i=index;" [class.active]="entity.selected" (click)="chooseEntity(i)">
              {{(entity.name) ? entity.name : entity.alpha_id}}
            </div>
          </div>
          <div class="blok" *ngIf="(resolveView || checkView)">
            <div class="item tag" *ngFor="let entity of auditEntities; let i=index;" [class.active]="entity.selected">
              {{(entity.name) ? entity.name : entity.alpha_id}}
            </div>
          </div>
        </div>

        <!--<mat-form-field class="form-field" appearance="outline">
          <mat-label>Custom entity</mat-label>
          <textarea matInput [formControl]="customAuditEntity" cdkTextareaAutosize></textarea>
        </mat-form-field>-->

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Custom entities</mat-label>
          <mat-chip-list *ngIf="!(resolveView || checkView)" #customEntitiesList>
            <mat-chip *ngFor="let entity of customAuditEntities" [selectable]="selectable" [removable]="removable" (removed)="removeCustomEntity(entity)">
              {{entity}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input matInput #customEntitiesInput [formControl]="customEntitiesControl" [matAutocomplete]="auto" [matChipInputFor]="customEntitiesList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addCustomEntity($event)">
          </mat-chip-list>
          <mat-chip-list *ngIf="(resolveView || checkView)">
            <mat-chip *ngFor="let entity of customAuditEntities" [selectable]="false" [removable]="false">
              {{entity}}
            </mat-chip>
          </mat-chip-list>
          <mat-autocomplete class="distinct-panel" #auto="matAutocomplete" (optionSelected)="selectedCustomEntity($event)">
            <mat-option *ngFor="let entity of filteredCustomEntities | async | orderBy" [value]="entity">
              {{entity}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div class="info">
          <div class="main-tito" *ngIf="!(resolveView || checkView)">Add related content (required)</div>
          <div class="main-tito" *ngIf="(resolveView || checkView)">Related content</div>
          <div class="blok" *ngIf="!(resolveView || checkView)">
            <!--<div class="item tag" [class.active]="contentType==='article'" (click)="getContent('article')">Full article</div>-->
            <div class="item tag" *ngIf="newsinfo.events && (newsinfo.events.length > 0)" [class.active]="contentType==='custom'" (click)="getContent('custom')">Custom</div>
            <div class="item tag" *ngIf="newsinfo.events && (newsinfo.events.length > 0)" [class.active]="contentType==='event'"  (click)="getContent('event')">Copy events</div>
          </div>
        </div>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Content</mat-label>
          <textarea matInput required [formControl]="newsAuditContent" (focus)="getContent('edit')" cdkTextareaAutosize></textarea>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label *ngIf="!(params.newstype === 'Flagged' && (resolveView || checkView))">Comment</mat-label>
          <mat-label *ngIf="(params.newstype === 'Flagged' && (resolveView || checkView))">Open comment</mat-label>
          <textarea matInput [formControl]="newsAuditComment" cdkTextareaAutosize></textarea>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline" *ngIf="(auditView) || (params.newstype === 'Flagged')">
          <mat-label>Notify email</mat-label>
          <input matInput
                 autocomplete="email"
                 type="email"
                 pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                 [formControl]="newsAuditNotify">
        </mat-form-field>

        <mat-form-field *ngIf="(params.newstype === 'Flagged' && (resolveView || checkView))" class="form-field" appearance="outline">
          <mat-label>Resolve comment</mat-label>
          <textarea matInput [formControl]="newsResolveComment" cdkTextareaAutosize></textarea>
        </mat-form-field>

      </div>

      <div class="main-blok footer" *ngIf="!(resolveView || checkView)">
        <div mat-dialog-actions>
          <button mat-raised-button color="secondary" mat-dialog-close>CANCEL</button>
          <button mat-raised-button color="primary" class="flag-submit" (click)="newsSubmit()" *ngIf="(validateAuditEntities || (customAuditEntities && (customAuditEntities.length !== 0))) && (newsAuditContent.value && (newsAuditContent.value !== ''))">SUBMIT</button>
        </div>
      </div>
      <div class="main-blok footer" *ngIf="(resolveView)">
        <div mat-dialog-actions>
          <button mat-raised-button color="secondary" mat-dialog-close>CANCEL</button>
          <button mat-raised-button color="primary" (click)="newsResolve()">RESOLVE</button>
        </div>
      </div>

    </div>

    <div class="auditmain reportmain" *ngIf="reportView && !( auditView || dismissView || resolveView || checkView )">
      <app-newsreport [reportData]="reportData" [(reportView)]="reportView"></app-newsreport>
    </div>

  </div>

</div>
